import React from 'react'
import {Container,Col, Row, Nav, Navbar } from 'react-bootstrap'
import { WhatsAppOutlined, FacebookFilled, LinkedinFilled, TwitterSquareFilled ,MailOutlined  } from '@ant-design/icons';
import logo from '../assests/img/logo.jpg'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faEnvelope } from '@fortawesome/free-solid-svg-icons'



function Header() {
 
  
  return (
    <>
    {/* <div  style={{overflow:'hidden'}}>
<Row className='pt-2 right pb-0 mb-0'>
<Col xm={6}>
    <div className='contact-details' style={{overflow:'hidden'}}>
    <div className='whatsapp'>
    <a href='https://wa.me/+923345900187' className='d-flex align-items-center text-decoration-none'>
    <WhatsAppOutlined className='txt-clr' />
        <span className='me-2 number'>
        &nbsp;+923345900187
        </span>
    </a>
    </div>
    <div className='whatsapp'>
    <a href='https://wa.me/+923345900187' className='d-flex align-items-center text-decoration-none'>
    <MailOutlined  className='txt-clr'/>
        <span className='me-2 number'>
        &nbsp;info@resengsrv.com
        </span>
        
    </a>
    </div>
    </div>
</Col>


<Col xm={6}>
<div className='right' >
<FacebookOutlined className='text-primary ab txt-color' />
<LinkedinOutlined className='ms-2 me-2 text-primary ab txt-color' />
<TwitterOutlined className=' text-primary ab txt-color' />
</div>
</Col>

</Row>
<Row>
  <Col xs={12} >
    
  </Col>
</Row>
<Row className='pt-3 pb-3'>
    <Col ssm={6} >
    <img src={logo} width={200} />
    </Col>
    <Col ssm={6}>
    <Navbar  expand="lg" className='pt-0'>
      <Container className='justify-content-end me-0 pe-0'>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='justify-content-end flex-grow-0' id="basic-navbar-nav">
          <Nav className="me-0 ">
            <Nav.Link  href="/" className='txt-color-secndry'>Home</Nav.Link> 
             <Nav.Link href="services"   className='txt-color-secndry' >Services</Nav.Link>
            <Nav.Link href="about"  className='pe-0 txt-color-secndry'>About</Nav.Link> 
             <Nav.Link href="contact"   className='pe-0 txt-color-secndry'>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </Col>
</Row>
    </div>
    <div> */}
{/*      
    <div style={{display:'flex', flexDirection:'row', backgroundColor:'lightblue',width:'100%'  }} >
   <div style={{width:'70%', backgroundColor:'green' }} >
   <a href='https://wa.me/+923345900187' className='align-items-center text-decoration-none'>
    <WhatsAppOutlined className='txt-clr' />
        <span className='me-2 number'>
        &nbsp;+923345900187
        </span>
    </a>
    <MailOutlined  className='txt-clr'/>
        <span className='me-2 number'>
        &nbsp;info@resengsrv.com
        </span>

   </div>

   <div style={{width:'30%' }} >
       
      
      <FacebookOutlined className='text-primary ab txt-color ' />
<LinkedinOutlined className='ms-2 me-2 text-primary ab txt-color' />
<TwitterOutlined className=' text-primary ab txt-color' />
      
       </div>

       </div> */}
       <div className='Background pt-1'>
      <div className="d-flex justify-content-between container align-item-baseline">
<div className='d-flex align-item-baseline'>

<div className="align-item-baseline">
<a href='https://wa.me/+923345900187' className='d-flex align-items-center text-decoration-none'>
    <WhatsAppOutlined className='txt-clr' />
        <span className='me-2 number txt-clr'>
        &nbsp;+923345900187
        </span>
    </a>
</div>
    <div className="align-item-baseline">
    <a href='https://wa.me/+923345900187' className='d-flex align-items-center text-decoration-none'>
    <FontAwesomeIcon icon={faEnvelope}  className='txt-clr' style={{fontWeight:'500'}}/>
        <span className='me-2 number txt-clr'>
        &nbsp;info@resengsrv.com
        </span>
    </a>
    </div>

</div>
<div  className='align-item-baseline d-n'>
<FacebookFilled  className='text-primary  txt-clr' />
<LinkedinFilled className='ms-2 me-2   txt-clr' />
<TwitterSquareFilled  className=' text-primary   txt-clr' />

      
</div>
       </div>
       </div>
      
      <div className="d-flex justify-content-between container pt-2 pb-2">
<div className=''>

<img src={logo} width={200} />

</div>
<div  className='p-0 ms-2'>
<Navbar  expand="lg" className='p-0 m-0'>
      
        <Navbar.Toggle aria-controls="basic-navbar-nav"className="p-0 m-0" />
        <Navbar.Collapse className='justify-content-end flex-grow-0' id="basic-navbar-nav">
          <Nav className="me-0 ">
            <Nav.Link  href="/" className='txt-color-secndry ' style={{fontSize:'1.1rem', fontWeight:'500'}}>Home</Nav.Link> 
             <Nav.Link href="services"   className='txt-color-secndry ms-4' style={{fontSize:'1.1rem', fontWeight:'500'}} >Services</Nav.Link>
            <Nav.Link href="about"  className='pe-0 txt-color-secndry ms-4 ' style={{fontSize:'1.1rem', fontWeight:'500'}}>About</Nav.Link> 
             <Nav.Link href="contact"   className='pe-0 txt-color-secndry ms-4' style={{fontSize:'1.1rem', fontWeight:'500'}}>Contact</Nav.Link>
             <Nav.Link href="news"   className='pe-0 txt-color-secndry ms-4' style={{fontSize:'1.1rem', fontWeight:'500'}}>Events & News</Nav.Link>
          </Nav>
        </Navbar.Collapse>
     
    </Navbar>
      
</div>
       </div>
  
    
    </>
  )
}

export default Header