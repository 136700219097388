import React from 'react'
import {Card, Button, Carousel,Row,Col} from 'react-bootstrap'
import a from '../assests/img/a.png'
import b from '../assests/img/b.png'
import electrical from '../assests/img/electrical.png'
import access from '../assests/img/accessControl.png'
import supply from '../assests/img/supply.png'
import cctv from '../assests/img/cctv.png'
import Cards from '../components/Cards'
import intro from '../assests/vedio/intro.mp4'
import { DefaultPlayer as Video } from 'react-html5video';
import c from '../assests/img/c.png'
import 'react-html5video/dist/styles.css';
import { motion } from "framer-motion"
import Contactform from '../components/Contactform'

function Home() {
  return (
    <div className=' pt-1 pb-5' style={{overflow:'hidden'}} >
         <div  style={{overflow:'hidden'}}>
         <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={a}
          alt="First slide"
        />
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={b}
          alt="Second slide"
        />
{/* 
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={c}
          alt="Third slide"
        />

        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
         </div>
         <section className="welcome container">
         <Row>
         <Col  sm={6} >
         <h1 >Welcome to RES EngineeringServices Pvt LTD</h1>
         <div className="welcom-main-pragraph">
          <p>We provide Services &amp; market supplies. Resedentioal and commercial electrical installatin and mentainence. Solar pannel installation,CCTV camras installation and its access control system.</p>
         </div>
         </Col>
         <Col sm={6}>

         <div className="welcom-support-pragraph">
          <p>We provide Services &amp; market supplies. Resedentioal and commercial electrical installatin and mentainence. Solar pannel installation,CCTV camras installation and its access control system.</p>
         </div>
         </Col>
         </Row>
         </section>
         <section className="services">
           <Row>
             <Col sm={3}>

         <Cards title="Electrical Installation" src={electrical} body="We have a complete range of electrical solutions for all electrical related problems. Be it repair and maintenance or any installation work. Our team of professionals handle all the electricity tasks skillfully, and give best electrician services and advice accordingly. Our company firmly believes in quality and strives to give best in every field of area."/>
             </Col>
             <Col sm={3}>

         <Cards  title="CCTV Installation" src={cctv} body="With the increase in crime in the vicinity, cctv camera set up services in Pakistan are on a boom. Such is the insecurity of humans that from public roads, locality, offices, buildings to houses, each person desires to have a personal cctv camera for them. However simplest few is aware of which type suits them quality and what will be the proper location of its installation."/>
             </Col>
             <Col sm={3}>

         <Cards  title="Access Control System " src={access} body="Modern world needs modern security solutions. In the time of uncertainty, security measures need to be considered by the organizations and also by the individuals for themselves and for their loved ones. Outsourcing security services gives peace of mind, and a sense of assurance that your property and your close ones are safe." />
             </Col>
             <Col sm={3}>

         <Cards  title="Supplies" src={supply} body="Offer ISO High Quality Cable Ties. UL, VDE, CE, DNV. GL, ABS, CSA cert. Contact Us. Professional Cable Tie manufacturer Offer Wide Selection of Cable Ties. Wiring accessories manuf. Made in Taiwan. Serving 127 countries. ISO 9001 certificated. Sell your new and used parts & equipment to us! We buy all electrical, pneumatic, hydraulic, HVAC, and other industrial parts" />
             </Col>
           </Row>
         </section>
       <sectioin className="intro container">
       <Row>

         <Col sm={6}>
         {/* <video style={{width:"100%", height:"auto", preload:"auto"}} >
        <source src={intro} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video> */}
      {/* <ReactPlayer url={intro} /> */}
      <Video
      style={{width:"100%", height:'50vh'}}
     
            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
           
            onCanPlayThrough={() => {
                // Do stuff
            }}>
            <source src={intro} type="video/webm" />
            {/* <track label="English" kind="subtitles" srcLang="en" src="http://source.vtt" default /> */}
        </Video>
         </Col>
         <Col sm={6} >
           <div className='d-flex align-item-center ab ps-2'>
          <div >
          <p>
           
           We provide Services & market supplies. Resedentioal and commercial electrical installatin and mentainence. Solar pannel installation,CCTV camras installation and its access control system.
           We provide Services & market supplies. Resedentioal and commercial electrical installatin and mentainence. Solar pannel installation,CCTV camras installation and its access control system.
                      </p>
          </div>
           </div>
          
         </Col>
       </Row>
       </sectioin>
      
      <section className="contact-us">
      <h2 style={{textAlign:'center', marginTop:'2rem'}}>Contact us</h2>
      <div className='container'>
      <Contactform />
      </div>
      {/* <hr style={{width:'3rem',alignItems:'center',marginLeft:'49%', color:'red',backgroundColor:"red",borderWidth:'3px'}}></hr> */}

      </section>
    </div>
  )
}

export default Home