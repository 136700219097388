import React from 'react'

function About() {
    return (
        <div>
            <section className="about">
             <h2 style={{textAlign:'center'}}>Vesion & mission</h2>
             <div className="">
             <div className="apologize">
                 <h1 style={{textAlign:'center'}}>Apologize</h1>
                 <h3 style={{textAlign:'center'}}>Website is under maintenance</h3>
             </div>
             </div>
            </section>
        </div>
    )
}

export default About
