import React,{useState} from 'react'
import {Row, Col, Button} from 'react-bootstrap'
function Contactform() {
    const [name, setname] = useState(null);
    return (
        
      
    <div className="row contactusform1">
           <div className=" col-sm-2">


           </div>
          <div className=" col-sm-8">
              <Row>
                  <Col sm={6}>
          <input type="text" class="form-control" placeholder="Enter Your Name" id="pwd" onChange={(e)=>setname(e.target.value)} value={name} />

                  </Col>
                  <Col sm={6}>
          <input type="text" class="form-control" placeholder="Enter Your Email" id="pwd" onChange={(e)=>setname(e.target.value)} value={name} />

                  </Col>
              </Row>
              <Row className="mt-4">
                  <Col sm={12}>
          <input type="text" class="form-control" placeholder="Subject" id="pwd" onChange={(e)=>setname(e.target.value)} value={name} />

                  </Col>

              </Row>
              <Row className="mt-4">
                  <Col sm={12}>
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"></textarea>

                  </Col>

              </Row>
              <div>
                 
                 <div style={{display:'flex',}}>



              <Button style={{marginLeft:'auto', marginRight:'auto',padding:'0.8rem', fontSize:'1.2rem'}} className='mt-4' variant="primary" size="sm">Send Message</Button>
                 </div>
                  

              </div>

          </div>
          <div className=" col-sm-2">


</div>
    </div> 
    )
}

export default Contactform
