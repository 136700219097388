import React from 'react'
import {Row, Col,Container} from 'react-bootstrap'

function Footer() {
  return (
    <div className='pt-5 pb-2' style={{backgroundColor:'black',overflow:'hidden'}}>
   
    <div className="container">
    <Row style={{color:'#fff'}}>
        <Col sm={3}>
            <h4 style={{lineHeight:'1.9rem',marginBottom:"2rem"}}>RES Engineering <br></br>Services Pvt LTD,</h4>
            <p>Office no 31,secound floor<br></br>
            Trnol Center Plaza, Tarnol, 
<br></br>
Islamabad
</p>
        </Col>
        <Col sm={3}>
            <h5>
                Useful links
            </h5>
            <div>
                <ul>
                    <li>
                    <a href=''>Home</a>
                    </li>
                    <li>
                    <a href=''>Services</a>
                    </li>
                    <li>
                    <a href=''>About</a>
                    </li>
                    <li>
                    <a href=''>Contact</a>
                    </li>
                </ul>
            
            
            </div>
            
            
    

        </Col>
        <Col sm={6}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13284.507017464462!2d72.9013563256827!3d33.65387740292364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df97fb667dc803%3A0x7b809f441ddb53bd!2sTarnol%2C%20Islamabad%2C%20Islamabad%20Capital%20Territory%2C%20Pakistan!5e0!3m2!1sen!2s!4v1664093688441!5m2!1sen!2s" style={{width:"100%", height:"auto", overflow:'hidden'}}   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Col>
    </Row>
    <p style={{color:'#fff', textAlign:'center'}} className='pt-5 mt-5 text-alin-center'>&copy;RES <span className='b'>Engineering</span> Services Pvt ltd
    <br></br>
    <ul>
        <li>Design & deveoped by <span> <a href=''>SE Shahab uddin</a> </span></li>
    </ul>
    </p>
    </div>

   
   </div>
    
  )
}

export default Footer