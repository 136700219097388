import React from 'react'
import {Card, Button} from 'react-bootstrap'
function Cards({title,body,src}) {
    return (
        <div className="d-flex justify-content-around ">
        <Card style={{ width: '18rem',}} className="shadow">
          <Card.Img variant="top" src={src} />
          <Card.Body>
    <Card.Title className='title '>{title}</Card.Title>
            <Card.Text>
             {body}
            </Card.Text>
            <Button variant="primary">Details</Button>
          </Card.Body>
        </Card>
        </div>
    )
}

export default Cards
