import React from 'react'

function Contact() {
    return (
        <section className="about">
        <h2 style={{textAlign:'center',}}>Contact us</h2>
        <div className="">
        <div className="apologize">
            <h1 style={{textAlign:'center'}}>Apologize</h1>
            <h3 style={{textAlign:'center'}}>Website is under maintenance</h3>
        </div>
        </div>
       </section>
    )
}

export default Contact
